.home {
  width: 100%;
  height: auto;
}

.bottomBar {
  background-color: black;
  color:#FBB040;
  
}

.reportSample {
  background-color: black;
  color:#FBB040;
}

.homeInspectBody {
  background-color: #FBB040;
  padding-top: 50px;
}
/* 



.bigAnt {
  width: 653px;
  height: 900px;
  padding-right: 250px;
}


.contactInfo {
padding-top: 25px;
color: black;


}


.modalAnt {
  height: 750px;
  width: 375px;
}

.inspectHome {
  position: relative;
  top: 0;
  left: 0;
}
.home {
  position: relative;
  top: 0;
  left: 0;
  
}

 */

 /* .social {
  padding-left: 1100px;
 } */
.img {
    width: 300px;
    height: 300px;
    padding-top: 20px;
}

.certs {
    background-color: #FBB040;;
}

.certImg {
    padding-top: 25px;
  }

  .internachis {
    padding-bottom: 25px;
  }